import { useLayoutEffect } from 'react';

export const useLockBodyScroll = (locked) => {
    useLayoutEffect(() => {
        if (locked) {
            document.documentElement.style.overflowY = 'hidden';
            document.body.style.overflowY = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'visible';
            document.body.style.overflowY = 'visible';
        }
        return () => {
            document.documentElement.style.overflowY = 'visible';
            document.body.style.overflowY = 'visible';
        };
    }, [locked]);
};
