import { useQuery } from '@apollo/client';

import { useLoading } from '../../hooks/useLoading';
import packagesPageOperations from '../../queries/getPackagesPage.gql';
import packagesMaterialsOperations from '../../queries/getPackagesMaterials.gql';

export const usePackagesPage = () => {
    const { getPackagesPage } = packagesPageOperations;
    const { getPackagesMaterials } = packagesMaterialsOperations;

    const { data: packagesPageData, loading: packagesPageLoading } = useQuery(
        getPackagesPage,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    const { data: packagesMaterialsData, loading: packagesMaterialsLoading } =
        useQuery(getPackagesMaterials, { fetchPolicy: 'cache-and-network' });

    const {
        Pretitle,
        Title,
        Description,
        ProductionDescription,
        ProductionPretitle,
        ProductionTitle,
    } = packagesPageData?.packagesPage || {};

    const materials = packagesMaterialsData?.packagesMaterials;

    const data = {
        pretitle: Pretitle,
        title: Title,
        description: Description,
        productionDescription: ProductionDescription,
        productionPretitle: ProductionPretitle,
        productionTitle: ProductionTitle,
        materials,
    };

    const isLoading = useLoading(
        packagesPageLoading || packagesMaterialsLoading,
        packagesPageData && packagesMaterialsData
    );

    return { data, isLoading };
};
