import { gql } from '@apollo/client';

const GET_HOMEPAGE = gql`
    {
        homepage {
            BannerTitle
            BannerText
            AboutUsPreTitle
            AboutUsTitle
            AboutUsDescription
            EdgesOfferPreTitle
            EdgesOfferTitle
            EdgesOfferDescription
            PackagesOfferPreTitle
            PackagesOfferTitle
            PackagesOfferDescription
            TechnologyPreTitle
            TechnologyTitle
            TechnologyDescription
        }
    }
`;

export default {
    getHomePage: GET_HOMEPAGE,
};
