import React from 'react';

import { logoType } from '../../constants';
import Logo from '../../components/Logo';
import classes from './maintenancePage.module.css';

// TODO Maintenace style, 404 and 5XX page

const MaintenancePage = () => {
    return (
        <div className={classes.root}>
            <Logo type={logoType.light} classes={classes.logo} />
            <div className={classes.content}>
                <h1 className={classes.title}>Prace techniczne</h1>
                <p className={classes.paragraph}>
                    Na stronie prowadzone są aktualnie prace techniczne,
                    dziękujemy za wyrozumiałość.
                </p>
            </div>
        </div>
    );
};

export default MaintenancePage;
