import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { appActionsTypes } from '../constants';
import { useAppContext } from '../contexts/appContext';
import { useLoading } from './useLoading';
import defaultOperations from '../queries/getConfig.gql';

export const useApp = () => {
    const [{ appState }, { actions }] = useAppContext();
    const { getConfigQuery } = defaultOperations;
    const { setMaintenance } = appActionsTypes;

    const { data, loading, error } = useQuery(getConfigQuery, {
        fetchPolicy: 'cache-and-network',
    });

    const configData = useMemo(() => {
        if (data && !loading && !error) {
            return data.config.maintenanceMode;
        }

        return null;
    }, [data, loading, error]);

    useEffect(() => {
        if (configData) {
            actions({ type: setMaintenance, payload: configData });
        }
    }, [configData]);

    useLoading(loading, data);

    return { appState };
};
