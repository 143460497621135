import React from 'react';
import { arrayOf, number, string } from 'prop-types';
import {
    Accordion as AccordionWrapper,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { icons, colors } from '../../constants';
import Icon from '../Icon';
import classes from './accordion.module.css';

const Accordion = (props) => {
    const { items } = props;

    return (
        <AccordionWrapper preExpanded={[0]} className={classes.root}>
            {items?.map((item, index) => (
                <AccordionItem key={item.Title} uuid={index}>
                    <AccordionItemHeading className={classes.heading}>
                        <AccordionItemButton className={classes.button}>
                            <Icon icon={icons.plus} color={colors.black} />
                            {item.Title}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={classes.panel}>
                        <p className={classes.content}>{item.Description}</p>
                    </AccordionItemPanel>
                </AccordionItem>
            ))}
        </AccordionWrapper>
    );
};

export default Accordion;

Accordion.propTypes = {
    items: arrayOf({
        title: string,
        content: string,
        id: number,
    }),
};
