import React from 'react';
import { shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

import { paths } from '../../constants';
import { useMinWidth } from '../../hooks/useMinWidth';

import bannerImg from '../../assets/images/homepageBannerImg.jpg';
import Button from '../Button';
import classes from './homepageBanner.module.css';

const HomepageBanner = (props) => {
    const { bannerData } = props;
    const { text, title } = bannerData;

    const isButton = useMinWidth(410);

    return (
        <section className={classes.root}>
            <img className={classes.background} src={bannerImg} alt={title} />
            <div className={classes.titleWrapper}>
                <h1
                    className={classes.title}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <p className={classes.paragraph}>{text}</p>
                {isButton ? (
                    <Link to={paths.aboutUs}>
                        <Button className={classes.button} priority="normal">
                            Sprawdź!
                        </Button>
                    </Link>
                ) : null}
            </div>
        </section>
    );
};

export default HomepageBanner;

HomepageBanner.propTypes = {
    bannerData: shape({
        text: string,
        title: string,
    }),
};
