import { gql } from '@apollo/client';

const GET_ABOUT_US_PAGE = gql`
    {
        aboutUsPage {
            Pretitle
            Title
            Description
            AccordionPretitle
            AccordionTitle
            AccordionDescription
            GalleryPretitle
            GalleryTitle
            GalleryDescription
            AdvantagesPretitle
            AdvantagesTitle
        }
    }
`;

export default {
    getAboutUsPage: GET_ABOUT_US_PAGE,
};
