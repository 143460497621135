export const devicesWidth = {
    desktop: 1024,
    tablet: 768,
    mobile: 320,
};

export const logoType = {
    dark: 'dark',
    light: 'light',
};

export const loaderType = {
    dark: 'dark',
    light: 'light',
};

export const paths = {
    homepage: '/',
    furnitureEdges: '/obrzeza-meblowe',
    foilWrapper: '/opakowania-foliowe',
    aboutUs: '/o-firmie',
    contact: '/kontakt',
};

export const appActionsTypes = {
    setMaintenance: 'setMaintenance',
    setMobileMenuOpen: 'setMobileMenuOpen',
    setIsLoading: 'setIsLoading',
};

export const icons = {
    plus: 'plus',
    orange: 'orange',
    shape3d: 'shape3d',
    glossy: 'glossy',
    grid: 'grid',
    leaf: 'leaf',
    pallette: 'pallette',
    rectangle: 'rectangle',
    shape: 'shape',
    tree: 'tree',
};

export const colors = {
    white: '#FFFFFF',
    black: '#000000',
    blue: '#637292',
    brown: '#78484E',
    grenade: '#35435D',
};
