import React from 'react';

import { useEdgesPage } from './useEdgesPage';
import { icons, colors } from '../../constants';
import Icon from '../../components/Icon';
import LoaderIndicator from '../../components/LoaderIndicator';
import MaterialsSection from '../../components/MaterialsSection/materialsSection';
import edgesTitleImg from '../../assets/images/edgesTitle.jpg';
import edgesTitleMobileImg from '../../assets/images/edgesTitleMobile.jpg';
import PageTitleSection from '../../components/PageTitleSection/pageTitleSection';
import classes from './edgesPage.module.css';

const EdgesPage = () => {
    const { data, isLoading } = useEdgesPage();

    const { pretitle, title, description, materials } = data;

    if (isLoading) {
        return <LoaderIndicator fullScreen />;
    }

    return (
        <>
            <PageTitleSection
                title="OBRZEŻA MEBLOWE"
                description="Aluplastika - Jesteśmy na dobrej krawędzi"
                image={edgesTitleImg}
                mobileImage={edgesTitleMobileImg}
            />
            <main className={classes.root}>
                <div className={classes.wrapper}>
                    <MaterialsSection
                        pretitle={pretitle}
                        title={title}
                        description={description}
                        image="edges"
                        materials={materials}
                    />
                </div>
                <div className={classes.structuresWrapper}>
                    <div className={classes.wrapper}>
                        <div className={classes.structures}>
                            <h2>STRUKTURY</h2>
                            <p>
                                Nasze obrzeża wykonujemy nie tylko w szerokiej
                                gamie kolorystycznej i rozmiarowej ale także w
                                różnych strukturach dopasowanych do płyty i
                                innych dodatków wizualnych.
                            </p>
                            <p>
                                <strong>
                                    Dopasuj tak jak chcesz - wybierz odpowiednią
                                    strukturę!
                                </strong>
                            </p>
                        </div>
                        <div className={classes.structuresIcons}>
                            <div className={classes.icon}>
                                <Icon
                                    icon={icons.orange}
                                    color={colors.white}
                                    width={32}
                                    height={32}
                                />
                                <h5>Molet (skórka pomarańczy)</h5>
                                <p>
                                    Stwórz swój eklektyzm w obrzeżach meblowych
                                </p>
                            </div>
                            <div className={classes.icon}>
                                <Icon
                                    icon={icons.glossy}
                                    color={colors.white}
                                    width={32}
                                    height={32}
                                />
                                <h5>Połysk</h5>
                                <p>Elegancja na wyciągniecie ręki</p>
                            </div>
                            <div className={classes.icon}>
                                <Icon
                                    icon={icons.tree}
                                    color={colors.white}
                                    width={32}
                                    height={32}
                                />
                                <h5>Drewno</h5>
                                <p>Postaw na zawsze pasującą klasykę</p>
                            </div>
                            <div className={classes.icon}>
                                <Icon
                                    icon={icons.rectangle}
                                    color={colors.white}
                                    width={32}
                                    height={32}
                                />
                                <h5>Gładki/mat</h5>
                                <p>Może to będzie Twój wybór!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.characteristicsWrapper}>
                    <h2>ZALETY NASZYCH OBRZEŻY</h2>
                    <div className={classes.characteristics}>
                        <div className={classes.characteristic}>
                            <div>
                                <Icon
                                    icon={icons.pallette}
                                    width={92}
                                    height={92}
                                    color={colors.white}
                                />
                            </div>
                            <p>Szeroka gama kolorów i rozmiarów</p>
                        </div>
                        <div className={classes.characteristic}>
                            <div>
                                <Icon
                                    icon={icons.shape3d}
                                    width={92}
                                    height={92}
                                    color={colors.white}
                                />
                            </div>
                            <p>
                                Grubości od 0,4 mm do 2 mm, szerokości od 15 mm
                                do 63 mm
                            </p>
                        </div>
                        <div className={classes.characteristic}>
                            <div>
                                <Icon
                                    icon={icons.shape}
                                    width={92}
                                    height={92}
                                    color={colors.white}
                                />
                            </div>
                            <p>Bogata paleta dekorów i unidekorów </p>
                        </div>
                        <div className={classes.characteristic}>
                            <div>
                                <Icon
                                    icon={icons.leaf}
                                    width={92}
                                    height={92}
                                    color="transparent"
                                />
                            </div>
                            <p>Spełnione normy bezpieczeństwa i ekologiczne</p>
                        </div>
                        <div className={classes.characteristic}>
                            <div>
                                <Icon
                                    icon={icons.grid}
                                    width={92}
                                    height={92}
                                    color={colors.white}
                                />
                            </div>
                            <p>Dopasowanie do płyt kluczowych producentów</p>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default EdgesPage;
