import React from 'react';
import Slider from 'react-slick';

import images from '../../../assets/images/gallery/index';
import classes from './gallery.module.css';

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3500,
    autoplay: true,
    swipeToSlide: true,
};

const Gallery = () => {
    const items = Object.values(images).map((image) => (
        <img key={image} src={image} alt="Zdjęcie w galerii" />
    ));

    return (
        <div className={classes.root}>
            <Slider {...settings}>{items}</Slider>
        </div>
    );
};

export default Gallery;
