import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import defaultOperations from '../../queries/getFooter.gql';

export const useFooter = () => {
    const { getFooter } = defaultOperations;

    const { data } = useQuery(getFooter);

    const footerData = useMemo(() => {
        if (data) {
            const { ColumnOne, ColumnTwo, ColumnThree } = data.footer;
            return {
                columnOne: ColumnOne,
                columnTwo: ColumnTwo,
                columnThree: ColumnThree,
            };
        }

        return {};
    }, [data]);

    return { footerData };
};
