import { useQuery } from '@apollo/client';

import { useLoading } from '../../hooks/useLoading';
import edgesPageOperations from '../../queries/getEdgesPage.gql';
import edgesMaterialsOperations from '../../queries/getEdgesMaterials.gql';

export const useEdgesPage = () => {
    const { getEdgesPage } = edgesPageOperations;
    const { getEdgesMaterials } = edgesMaterialsOperations;

    const { data: edgesPageData, loading: edgesPageLoading } = useQuery(
        getEdgesPage,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    const { data: edgesMaterialsData, loading: edgesMaterialsLoading } =
        useQuery(getEdgesMaterials, {
            fetchPolicy: 'cache-and-network',
        });

    const { Pretitle, Title, Description } = edgesPageData?.edgesPage || {};

    const materials = edgesMaterialsData?.edgesMaterials;

    const data = {
        pretitle: Pretitle,
        title: Title,
        description: Description,
        materials,
    };

    const isLoading = useLoading(
        edgesPageLoading || edgesMaterialsLoading,
        edgesPageData && edgesMaterialsData
    );

    return { data, isLoading };
};
