import React from 'react';
import { string } from 'prop-types';

import Gallery from './Gallery';
import classes from './gallerySection.module.css';

const GallerySection = ({ title, description }) => {
    return (
        <section className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.info}>
                    <h2>{title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                <Gallery />
            </div>
        </section>
    );
};

export default GallerySection;

GallerySection.propTypes = {
    title: string,
    description: string,
};
