import React from 'react';

import contactTitleImg from '../../assets/images/contactTitle.jpg';
import contactTitleMobileImg from '../../assets/images/contactTitleMobile.jpg';
import PageTitleSection from '../../components/PageTitleSection/pageTitleSection';
import classes from './contactPage.module.css';

const ContactPage = () => {
    return (
        <>
            <PageTitleSection
                title="Kontakt"
                description="Aluplastika - Jesteśmy na dobrej krawędzi"
                image={contactTitleImg}
                mobileImage={contactTitleMobileImg}
            />
            <main className={classes.root}>
                <div className={classes.wrapper}>
                    <div className={classes.circle}>
                        <h2>Dowiedz się więcej o firmie</h2>
                        <span>Gdzie szukać firmy Aluplastika-Bis?</span>
                        <div className={classes.cardAddress}>
                            <h3>Adres</h3>
                            <span>
                                Aluplastika-Bis Sp. z o.o.
                                <br />
                                ul. Przemysłowa 1A
                                <br />
                                63-600 Kępno
                                <br />
                                NIP 6191999264
                                <br />
                                KRS 0001128089
                                <br />
                                REGON 301209000
                                <br />
                                BDO 000037797
                                <br />
                                tel. (62) 78 272 23
                                <br />
                                aluplastika@aluplastika.pl
                                <br />
                            </span>
                        </div>
                        <div className={classes.cardContact}>
                            <h3>Prezes Zarządu</h3>
                            <span>
                                Krzysztof Tęsiorowski
                                <br />
                                tel. 603-350-013
                                <br />
                                ktesiorowski@aluplastika.pl
                                <br />
                            </span>
                        </div>
                        <div className={classes.cardSales}>
                            <h3>Dział handlowy</h3>
                            <span>
                                <span className={classes.bold}>
                                    OBRZEŻA MEBLOWE
                                </span>
                                <br />
                                tel. (62) 78 272 23
                                <br />
                                tel. kom. 601-328-713
                                <br />
                                zamowienia@aluplastika.pl
                                <br />
                                <span className={classes.bold}>
                                    OPAKOWANIE FOLIOWE
                                </span>
                                <br />
                                tel. (62) 78 272 11
                                <br />
                                tel. kom. 603-350-492
                                <br />
                                wmisiak@akces-bis.com.pl
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.cta}>
                    <h2>SKONTAKTUJ SIĘ Z NAMI!</h2>
                    <p>
                        Pracownicy z chęcią odpowiedzą na wszelkie pytania
                        dotyczące produktów, oferty, jak i innych elementów
                        związanych z Aluplastika-Bis.
                        <br />
                        <br />
                        <b>
                            Przekonaj się o naszym wieloletnim doświadczeniu!
                            Zadzwoń!
                        </b>
                    </p>
                </div>
            </main>
        </>
    );
};

export default ContactPage;
