import React from 'react';
import { string, number } from 'prop-types';

import icons from '../../assets/icons/iconsLib.svg';

const styles = { display: 'inline-block', verticalAlign: 'middle' };

const Icon = (props) => {
    const { height, width, color, icon, classes } = props;

    return (
        <svg
            className={classes}
            style={styles}
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill={color}
        >
            <use xlinkHref={`${icons}#${icon}`} />
        </svg>
    );
};

export default Icon;

Icon.propTypes = {
    width: number,
    height: number,
    color: string,
    icon: string.isRequired,
    classes: string,
};

Icon.defaultProps = {
    width: 16,
    height: 16,
    color: '#000000',
};
