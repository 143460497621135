import React from 'react';

import classes from './frame.module.css';

const Frame = () => {
    return (
        <div className={classes.root}>
            <div className={classes.infoSection}>
                <span className={classes.preTitle}>Technologia w liczbach</span>
                <h3 className={classes.title}>Odkryj nasze możliwości</h3>
                <p className={classes.paragraph}>
                    W naszym parku maszynowym posiadamy:
                </p>
                <ul className={classes.list}>
                    <li>2 zakłady produkcyjne</li>
                    <li>5 hal produkcyjnych</li>
                    <li>3 nowoczesne linie do produkcji obrzeży meblowych</li>
                    <li>4 linie do lakierowania i aktywizacji powierzchni</li>
                    <li>4 maszyny do rozcinania</li>
                    <li>
                        5 nowoczesnych linii do produkcji folii opakowaniowych{' '}
                    </li>
                </ul>
                <p className={classes.paragraph}>Nasze możliwości:</p>
                <ul className={classes.list}>
                    <li>
                        produkcja obrzeży meblowych w grubości od 0,4 mm do 2 mm
                        oraz szerokości od 15 mm do 63 m
                    </li>
                    <li>
                        produkcja folii o obwodzie 6 metrów i grubości od 20 do
                        200 mikronów
                    </li>
                </ul>
            </div>
            <div className={classes.stepsSection}>
                <div className={classes.dot}>1</div>
                <div className={classes.dot}>2</div>
                <div className={classes.dot}>3</div>
                <div className={classes.dot}>4</div>
            </div>
        </div>
    );
};

export default Frame;
