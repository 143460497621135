import React from 'react';
import ReactMarkdown from 'react-markdown';

import { useFooter } from './useFooter';
import { logoType } from '../../constants';
import Logo from '../Logo';
import classes from './footer.module.css';

const Footer = () => {
    const { footerData } = useFooter();
    const { columnOne, columnTwo, columnThree } = footerData;

    return (
        <footer className={classes.root}>
            <div className={classes.wrapper}>
                <Logo type={logoType.light} classes={classes.logo} />
                <div className={classes.col}>
                    <ReactMarkdown>{columnOne}</ReactMarkdown>
                </div>
                <div className={classes.col}>
                    <ReactMarkdown>{columnTwo}</ReactMarkdown>
                </div>
                <div className={classes.col}>
                    <ReactMarkdown>{columnThree}</ReactMarkdown>
                </div>
                <div className={classes.col}>
                    <p>
                        <strong>RODO</strong>
                        <br />
                        <br />
                        Administratorem Pani/Pana danych osobowych
                        przetwarzanych jest Aluplastika-Bis Sp. z o.o. Sp.k. z
                        siedzibą Kępnie, ul. Przemysłowa 1A, 63-600 Kępno
                        Inspektorem ochrony danych w Spółce Aluplastika-Bis Sp.
                        z o.o. Sp.k. jest Pan Wojciech Urbański
                        <br />
                        <br />
                        e-mail: iod.wojciech.urbanski@info-trans.pl
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
