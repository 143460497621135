import React, { useCallback } from 'react';

import { useAppContext } from '../../contexts/appContext';
import { appActionsTypes } from '../../constants';
import classes from './menuHamburger.module.css';

const MenuHamburger = () => {
    const [{ appState }, { actions }] = useAppContext();
    const { mobileMenuOpen } = appState;

    const handleToggleMenu = useCallback(() => {
        actions({
            type: appActionsTypes.setMobileMenuOpen,
            payload: !mobileMenuOpen,
        });
    }, [mobileMenuOpen]);

    const buttonClasses = [
        classes.hamburger,
        classes.hamburgerCollapse,
        mobileMenuOpen ? classes.isActive : '',
    ].join(' ');

    return (
        <button
            className={buttonClasses}
            type="button"
            onClick={handleToggleMenu}
        >
            <span className={classes.hamburgerBox}>
                <span className={classes.hamburgerInner} />
            </span>
        </button>
    );
};

export default MenuHamburger;
