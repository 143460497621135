import React from 'react';
import { number, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

import classes from './offerCard.module.css';

const OfferCard = (props) => {
    const { label, id, pretitle, title, image, description, url } = props;

    return (
        <div className={classes.root}>
            <div className={classes.label}>{label}</div>
            <div className={classes.number}>{id}</div>
            <span className={classes.pretitle}>{pretitle}</span>
            <h4 className={classes.title}>{title}</h4>
            <img src={image} alt={title} />
            <p className={classes.description}>{description}</p>
            <Link to={url} className={classes.link}>
                Czytaj więcej
                <BsArrowRight />
            </Link>
        </div>
    );
};

export default OfferCard;

OfferCard.propTypes = {
    label: string,
    id: number,
    pretitle: string,
    title: string,
    image: string,
    description: string,
    url: string,
};
