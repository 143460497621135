import { gql } from '@apollo/client';

const GET_ACCORDION = gql`
    {
        accordions {
            Title
            Description
        }
    }
`;

export default {
    getAccordion: GET_ACCORDION,
};
