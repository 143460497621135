import React from 'react';

import { useHomePage } from './useHomePage';
import AboutUsSection from '../../components/AboutUsSection/aboutUsSection';
import LoaderIndicator from '../../components/LoaderIndicator';
import TechnologySection from '../../components/TechnologySection/technologySection';
import HomepageBanner from '../../components/HomepageBanner';

const HomePage = () => {
    const { bannerData, aboutUsData, offerData, technologyData, isLoading } =
        useHomePage();

    if (isLoading) {
        return <LoaderIndicator fullScreen />;
    }

    return (
        <>
            <HomepageBanner bannerData={bannerData} />
            <AboutUsSection offerData={offerData} aboutUsData={aboutUsData} />
            <TechnologySection />
        </>
    );
};

export default HomePage;
