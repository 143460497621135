import React, { useMemo } from 'react';
import { object } from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { paths } from '../../constants';
import edgesImage from '../../assets/images/edges-offer.png';
import packagesImage from '../../assets/images/packages-offer.png';
import mockupImage from '../../assets/images/aboutUsMockup.jpg';
import OfferCard from './OfferCard/offerCard';
import Button from '../Button';
import classes from './aboutUsSection.module.css';

const AboutUsSection = ({ aboutUsData, offerData }) => {
    const { pretitle, title, description } = aboutUsData;

    const offerDataCards = useMemo(
        () => [
            {
                label: 'Obrzeża meblowe',
                id: 1,
                pretitle: offerData.edgesPretitle,
                title: offerData.edgesTitle,
                image: edgesImage,
                description: offerData.edgesDescription,
                url: paths.furnitureEdges,
            },
            {
                label: 'Opakowania foliowe',
                id: 2,
                pretitle: offerData.packagesPretitle,
                title: offerData.packagesTitle,
                image: packagesImage,
                description: offerData.packagesDescription,
                url: paths.foilWrapper,
            },
        ],
        [offerData]
    );

    return (
        <section className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.aboutUs}>
                    <div className={classes.aboutUsInfo}>
                        <h2 className={classes.title}>{title}</h2>
                        <p
                            className={classes.description}
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                        <Link to={paths.aboutUs}>
                            <Button priority="white" className={classes.button}>
                                Czytaj więcej
                            </Button>
                        </Link>
                    </div>
                    <div className={classes.aboutUsMockup}>
                        <img
                            src={mockupImage}
                            alt={title}
                            className={classes.mockup}
                        />
                    </div>
                </div>
                <div className={classes.offerTitle}>
                    <h2 className={classes.title}>Odkryj nasze produkty</h2>
                </div>
                <div className={classes.offer}>
                    {offerDataCards.map((card) => (
                        <OfferCard key={card.id} {...card} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default AboutUsSection;

AboutUsSection.propTypes = {
    aboutUsData: object,
    offerData: object,
};
