import React from 'react';

import image from '../../assets/images/technology-image.png';
import Frame from './Frame';
import classes from './technologySection.module.css';

const TechnologySection = () => {
    return (
        <section className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.infoSection}>
                    <Frame />
                </div>
                <div className={classes.imageSection}>
                    <img src={image} alt="Technologia" />
                </div>
            </div>
        </section>
    );
};

export default TechnologySection;
