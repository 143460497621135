import React from 'react';
import { bool, string } from 'prop-types';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { loaderType, colors } from '../../constants';
import classes from './loaderIndicator.module.css';

const LoaderIndicator = ({ fullScreen, type = loaderType.dark }) => {
    const rootClass = fullScreen ? classes.rootFullScreen : classes.root;
    const color = type === loaderType.dark ? colors.grenade : colors.white;

    return (
        <aside className={rootClass}>
            <Loader type="Puff" color={color} height={80} width={80} visible />
        </aside>
    );
};

export default LoaderIndicator;

LoaderIndicator.propTypes = {
    fullScreen: bool,
    type: string,
};
