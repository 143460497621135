import React from 'react';
import { string, arrayOf, oneOf, shape } from 'prop-types';

import edgesImg from '../../assets/images/edgesImg.jpg';
import packagesImg from '../../assets/images/packagesImg.jpg';
import classes from './materialsSection.module.css';

const EDGES = 'edges';
const PACKAGES = 'packages';

const MaterialsSection = ({
    pretitle,
    title,
    description,
    image,
    materials,
}) => {
    const imageUrl = image === EDGES ? edgesImg : packagesImg;

    return (
        <div className={classes.root}>
            <div className={classes.infoSection}>
                <div className={classes.info}>
                    <span>{pretitle}</span>
                    <h2>{title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                <div className={classes.image}>
                    <img src={imageUrl} alt={title} />
                </div>
            </div>
            <div className={classes.materials}>
                <div className={classes.labelMaterial}>
                    <h2>NAJWYŻSZEJ JAKOŚCI MATERIAŁY</h2>
                </div>
                {materials?.map((material) => (
                    <div key={material.title} className={classes.material}>
                        <h4>{material.Title}</h4>
                        <p>{material.Description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MaterialsSection;

MaterialsSection.propTypes = {
    pretitle: string,
    title: string,
    description: string,
    image: oneOf([EDGES, PACKAGES]),
    materials: arrayOf(
        shape({
            title: string,
            description: string,
        })
    ),
};
