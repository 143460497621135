import { gql } from '@apollo/client';

const GET_PACKAGES_MATERIALS = gql`
    {
        packagesMaterials {
            Title
            Description
        }
    }
`;

export default {
    getPackagesMaterials: GET_PACKAGES_MATERIALS,
};
