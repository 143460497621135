import { useEffect } from 'react';

import { useAppContext } from '../contexts/appContext';
import { appActionsTypes } from '../constants';

export const useLoading = (loading, data) => {
    const [{ appState }, { actions }] = useAppContext();
    const { setIsLoading } = appActionsTypes;
    const { isLoading } = appState;

    useEffect(() => {
        if (loading) {
            actions({ type: setIsLoading, payload: true });
        }
    }, [loading]);

    useEffect(() => {
        if (!data && loading) {
            return;
        }

        const timeOutId = setTimeout(() => {
            actions({ type: setIsLoading, payload: false });
        }, 400);

        return () => clearTimeout(timeOutId);
    }, [data, loading]);

    return isLoading;
};
