import React from 'react';

import { useAboutUsPage } from './useAboutUsPage';
import LoaderIndicator from '../../components/LoaderIndicator';
import PageTitleSection from '../../components/PageTitleSection/pageTitleSection';
import Accordion from '../../components/Accordion';
import aboutUsTitle from '../../assets/images/aboutUsTitle.jpg';
import aboutUsTitleMobile from '../../assets/images/aboutUsTitleMobile.jpg';
import aboutUsImage from '../../assets/images/aboutUsImage.jpg';
import classes from './aboutUsPage.module.css';
import GallerySection from '../../components/GallerySection/gallerySection';

const AboutUsPage = () => {
    const { data, isLoading } = useAboutUsPage();
    const {
        title,
        description,
        accordionTitle,
        galleryPretitle,
        galleryTitle,
        galleryDescription,
        advantagesPretitle,
        advantagesTitle,
        accordionItems,
    } = data || {};

    if (isLoading) {
        return <LoaderIndicator fullScreen />;
    }

    return (
        <>
            <PageTitleSection
                title="O firmie"
                description="Najwyższej jakości produkty - tylko w Aluplastice Bis"
                image={aboutUsTitle}
                mobileImage={aboutUsTitleMobile}
            />
            <main className={classes.root}>
                <div className={classes.wrapper}>
                    <section className={classes.info}>
                        <h2 className={classes.title}>{title}</h2>
                        <p className={classes.description}>{description}</p>
                        <div className={classes.timelineWrapper}>
                            <div className={classes.timelineItem}>
                                <h4 className={classes.timelineTitle}>2010</h4>
                                <p>Rozpoczęcie produkcji obrzeży meblowych.</p>
                            </div>
                            <div className={classes.timelineItem}>
                                <h4 className={classes.timelineTitle}>2012</h4>
                                <p>
                                    Zwiększenie mocy produkcyjnych poprzez zakup
                                    kolejnej linii produkcyjnej.
                                </p>
                            </div>
                            <div className={classes.timelineItem}>
                                <h4 className={classes.timelineTitle}>2015</h4>
                                <p>
                                    Rozszerzenie działalności produkcyjnej.
                                    Rozpoczęcie produkcji opakowań foliowych.
                                </p>
                            </div>
                            <div className={classes.timelineItem}>
                                <h4 className={classes.timelineTitle}>2018</h4>
                                <p>
                                    Zwiększenie parku maszynowego oraz
                                    stworzenie kompleksowego działu kontroli
                                    jakości.
                                </p>
                            </div>
                            <div className={classes.timelineItem}>
                                <h4 className={classes.timelineTitle}>2020</h4>
                                <p>
                                    Rozszerzenie produkcji poprzez wprowadzenie
                                    Polipropylenu do produkcji obrzeży
                                    meblowych.
                                </p>
                            </div>
                        </div>
                        <div className={classes.container}>
                            <div className={classes.accordion}>
                                <h2 className={classes.accordionTitle}>
                                    {accordionTitle}
                                </h2>
                                <Accordion items={accordionItems} />
                            </div>
                            <div className={classes.image}>
                                <div className={classes.sentence}>
                                    <span className={classes.quot}>&quot;</span>
                                    <p>
                                        Aluplastika - Jesteśmy na dobrej
                                        krawędzi
                                    </p>
                                </div>
                                <img
                                    src={aboutUsImage}
                                    alt="Aluplastika o nas"
                                />
                            </div>
                        </div>
                    </section>
                </div>
                <GallerySection
                    pretitle={galleryPretitle}
                    title={galleryTitle}
                    description={galleryDescription}
                />
                <div className={classes.advantagesWrapper}>
                    <div className={classes.advantages}>
                        <span>{advantagesPretitle}</span>
                        <h2>{advantagesTitle}</h2>
                        <div className={classes.items}>
                            <div className={classes.item}>
                                <h3>Współpraca</h3>
                                <p>
                                    Stawiamy na partnerską i długotrwałą relację
                                    z naszymi klientami, partnerami oraz
                                    dostawcami. Opieramy się na jasnych zasadach
                                    i chcemy aby każdy wspólny projekt był
                                    realizowany zgodnie z dobrymi praktykami.
                                </p>
                            </div>
                            <div className={classes.item}>
                                <h3>Technologia</h3>
                                <p>
                                    Posiadamy nowoczesny park maszynowy oraz
                                    zautomatyzowane urządzenia, dzięki czemu
                                    oferujemy produkty spełniające stale rosnące
                                    wymagania branży meblarskiej.
                                </p>
                            </div>
                            <div className={classes.item}>
                                <h3>Rozwój</h3>
                                <p>
                                    Wdrażamy innowacyjne technologie, poszerzamy
                                    zakres oferowanych produktów i usług,
                                    poszukujemy korzystnych rozwiązań, co
                                    przekłada się na wysoką jakość produktów i
                                    zadowolenie naszych Klientów.
                                </p>
                            </div>
                            <div className={classes.item}>
                                <h3>Kadra pracownicza</h3>
                                <p>
                                    Nasz team tworzą doświadczeni i kompetentni
                                    pracownicy. Zapewniamy rzetelną obsługę
                                    klienta. Pracujemy zespołowo i szukamy
                                    najlepszych rozwiązań.{' '}
                                    <b>Poznaj nas bliżej</b> 😊
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default AboutUsPage;
