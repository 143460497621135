import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useApp } from './hooks/useApp';
import { paths } from './constants';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import ContactPage from './pages/ContactPage';
import EdgesPage from './pages/EdgesPage';
import PackagesPage from './pages/PackagesPage';
import Header from './components/Header';
import Footer from './components/Footer/footer';
import BrandsSection from './components/BrandsSection';
import MaintenancePage from './pages/MaintenancePage/maintenancePage';
import globalCSS from './index.module.css';

const App = () => {
    const { appState } = useApp();

    if (appState.maintenanceMode) {
        return <MaintenancePage />;
    }

    return (
        <>
            <Header />
            <Switch>
                <Route exact path={paths.homepage} component={HomePage} />
                <Route exact path={paths.aboutUs} component={AboutUsPage} />
                <Route
                    exact
                    path={paths.foilWrapper}
                    component={PackagesPage}
                />
                <Route
                    exact
                    path={paths.furnitureEdges}
                    component={EdgesPage}
                />
                <Route exact path={paths.contact} component={ContactPage} />
            </Switch>
            <BrandsSection />
            <Footer />
        </>
    );
};

export default App;

App.globalCSS = globalCSS;
