import React from 'react';
import { string, boolean } from 'prop-types';
import { Link } from 'react-router-dom';

import { logoType, paths } from '../../constants';
import logoDark from './logoDark.png';
import logoLight from './logoLight.png';

const Logo = (props) => {
    const { type, isClickable, classes } = props;

    const imgSrc = type === logoType.light ? logoLight : logoDark;

    return isClickable ? (
        <Link to={paths.homepage} className={classes}>
            <img src={imgSrc} alt="Aluplastika logo" />
        </Link>
    ) : (
        <img src={imgSrc} alt="Aluplastika logo" className={classes} />
    );
};

export default Logo;

Logo.propTypes = {
    type: string,
    isClickable: boolean,
    classes: string,
};
