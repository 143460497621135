import React from 'react';
import { string, oneOf } from 'prop-types';

import classes from './button.module.css';

const getRootClass = (priority) => `root_${priority}Priority`;

const Button = (props) => {
    const { priority, children, type, className, ...rest } = props;

    const rootClass = [classes[getRootClass(priority)], className].join(' ');

    return (
        <button className={rootClass} type={type || 'button'} {...rest}>
            {children}
        </button>
    );
};

export default Button;

Button.propTypes = {
    priority: oneOf(['normal', 'white']),
    type: string,
    className: string,
};

Button.defaultProps = {
    priority: 'normal',
    type: 'button',
};
