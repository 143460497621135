import { gql } from '@apollo/client';

const GET_EDGES_MATERIALS = gql`
    {
        edgesMaterials {
            Title
            Description
        }
    }
`;

export default {
    getEdgesMaterials: GET_EDGES_MATERIALS,
};
