import React, { useEffect, useState, useCallback } from 'react';
import { array } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { appActionsTypes } from '../../constants';
import { useAppContext } from '../../contexts/appContext';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import classes from './mobileMenu.module.css';

const MobileMenu = ({ items }) => {
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

    const { pathname } = useLocation();
    const [{ appState }, { actions }] = useAppContext();
    const { mobileMenuOpen } = appState;

    useLockBodyScroll(mobileMenuOpen);

    const rootClass = mobileMenuOpen ? classes.rootOpen : classes.root;

    useEffect(() => {
        actions({ type: appActionsTypes.setMobileMenuOpen, payload: false });
    }, [pathname]);

    const handleSubmenuOpen = useCallback(() => {
        setIsSubmenuOpen((prevState) => !prevState);
    }, []);

    return (
        <aside className={rootClass}>
            <nav className={classes.navigation}>
                <ul>
                    {items.map((item) => (
                        <li
                            key={item.id}
                            className={[
                                item.url === pathname ? classes.isActive : '',
                                item.children?.length
                                    ? classes.haveSubmenu
                                    : '',
                                isSubmenuOpen && item.children?.length
                                    ? classes.isOpen
                                    : '',
                            ].join(' ')}
                        >
                            <Link
                                to={item.url ? item.url : '#'}
                                onClick={
                                    item.children?.length
                                        ? handleSubmenuOpen
                                        : null
                                }
                                onKeyDown={
                                    item.children?.length
                                        ? handleSubmenuOpen
                                        : null
                                }
                            >
                                {item.label}
                                {item.children ? (
                                    isSubmenuOpen ? (
                                        <IoIosArrowUp />
                                    ) : (
                                        <IoIosArrowDown />
                                    )
                                ) : null}
                            </Link>
                            {item?.children?.length ? (
                                <ul
                                    className={
                                        isSubmenuOpen
                                            ? classes.submenuOpen
                                            : classes.submenu
                                    }
                                >
                                    {item.children.map((child) => (
                                        <li key={child.id}>
                                            <Link to={child.url}>
                                                {child.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            ) : null}
                        </li>
                    ))}
                </ul>
            </nav>
        </aside>
    );
};

export default MobileMenu;

MobileMenu.propTypes = {
    items: array,
};
