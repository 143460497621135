import { gql } from '@apollo/client';

const GET_CONFIG_QUERY = gql`
    {
        config {
            maintenanceMode
        }
    }
`;

export default {
    getConfigQuery: GET_CONFIG_QUERY,
};
