import { useQuery } from '@apollo/client';

import { useLoading } from '../../hooks/useLoading';
import aboutUsOperations from '../../queries/getAboutUsPage.gql';
import accordionOperations from '../../queries/getAccordion.gql';

export const useAboutUsPage = () => {
    const { getAboutUsPage } = aboutUsOperations;
    const { getAccordion } = accordionOperations;

    const { data: aboutUsData, loading: aboutUsLoading } = useQuery(
        getAboutUsPage,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    const { data: accordionData, loading: accordionLoading } = useQuery(
        getAccordion,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    const {
        Pretitle,
        Title,
        Description,
        AccordionPretitle,
        AccordionTitle,
        AccordionDescription,
        GalleryPretitle,
        GalleryTitle,
        GalleryDescription,
        AdvantagesPretitle,
        AdvantagesTitle,
    } = aboutUsData?.aboutUsPage || {};

    const accordionItems = accordionData?.accordions;

    const data = {
        pretitle: Pretitle,
        title: Title,
        description: Description,
        accordionPretitle: AccordionPretitle,
        accordionTitle: AccordionTitle,
        accordionDescription: AccordionDescription,
        galleryPretitle: GalleryPretitle,
        galleryTitle: GalleryTitle,
        galleryDescription: GalleryDescription,
        advantagesPretitle: AdvantagesPretitle,
        advantagesTitle: AdvantagesTitle,
        accordionItems,
    };

    const isLoading = useLoading(
        aboutUsLoading && accordionLoading,
        aboutUsData || accordionData
    );

    return { data, isLoading };
};
