import { gql } from '@apollo/client';

const GET_FOOTER = gql`
    {
        footer {
            ColumnOne
            ColumnTwo
            ColumnThree
        }
    }
`;

export default {
    getFooter: GET_FOOTER,
};
