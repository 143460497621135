import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useMinWidth } from '../../hooks/useMinWidth';
import AkcesLogo from '../../assets/images/akces.png';
import AkcesGroupLogo from '../../assets/images/akcesGroup.png';
import TechfolLogo from '../../assets/images/techfol.png';
import AluplastikaLogo from '../../assets/images/aluplastika.png';
import classes from './brandSection.module.css';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 380,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

const BrandsSection = () => {
    const isMobileView = !useMinWidth(769);

    return isMobileView ? (
        <section className={classes.rootMobile}>
            <div className={classes.titleArea}>
                <h3 className={classes.title}>Nasza grupa</h3>
            </div>
            <Slider {...settings}>
                <img src={AluplastikaLogo} alt="Aluplastika" />
                <img src={TechfolLogo} alt="Techfol" />
                <img src={AkcesGroupLogo} alt="Akces Group" />
                <img src={AkcesLogo} alt="Akces" />
            </Slider>
        </section>
    ) : (
        <section className={classes.root}>
            <div className={classes.titleArea}>
                <h3 className={classes.title}>Nasza grupa</h3>
            </div>
            <div className={classes.wrapper}>
                <img src={AluplastikaLogo} alt="Aluplastika" />
                <img src={TechfolLogo} alt="Techfol" />
                <img src={AkcesGroupLogo} alt="Akces Group" />
                <img src={AkcesLogo} alt="Akces" />
            </div>
        </section>
    );
};

export default BrandsSection;
