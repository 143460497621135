import React from 'react';
import { string } from 'prop-types';

import { useMinWidth } from '../../hooks/useMinWidth';
import classes from './pageTitleSection.module.css';

const PageTitleSection = ({ title, description, image, mobileImage }) => {
    const isDesktop = useMinWidth(769);

    return (
        <section className={classes.root}>
            <div className={classes.wrapper}>
                <h1 className={classes.title}>{title}</h1>
                <span className={classes.description}>{description}</span>
            </div>
            <img
                src={isDesktop ? image : mobileImage}
                alt={title}
                className={classes.backgroundImg}
            />
        </section>
    );
};

export default PageTitleSection;

PageTitleSection.propTypes = {
    title: string,
    description: string,
    image: string,
    mobileImage: string,
};
