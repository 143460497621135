import React from 'react';

import { usePackagesPage } from './usePackagesPage';
import LoaderIndicator from '../../components/LoaderIndicator';
import packagesTitleImg from '../../assets/images/packagesTitle.jpg';
import packagesTitleMobileImg from '../../assets/images/packagesTitleMobile.jpg';
import PageTitleSection from '../../components/PageTitleSection/pageTitleSection';
import MaterialsSection from '../../components/MaterialsSection/materialsSection';
import packagesWeldImg from '../../assets/images/packagesWeldImg.jpg';
import packagesFormsImg from '../../assets/images/packagesFormsImg.jpg';
import classes from './packagesPage.module.css';

const PackagesPage = () => {
    const { data, isLoading } = usePackagesPage();

    const {
        pretitle,
        title,
        description,
        materials,
        productionPretitle,
        productionTitle,
        productionDescription,
    } = data;

    if (isLoading) {
        return <LoaderIndicator fullScreen />;
    }

    return (
        <>
            <PageTitleSection
                title="Opakowania foliowe"
                description="Nowoczesne linie produkcyjne "
                image={packagesTitleImg}
                mobileImage={packagesTitleMobileImg}
            />
            <main className={classes.root}>
                <div className={classes.wrapper}>
                    <MaterialsSection
                        pretitle={pretitle}
                        title={title}
                        description={description}
                        image="packages"
                        materials={materials}
                    />
                </div>
                <div className={classes.formsWrapper}>
                    <div className={classes.info}>
                        <span>{productionPretitle}</span>
                        <h2>{productionTitle}</h2>
                        <p>{productionDescription}</p>
                    </div>
                    <div className={classes.graphic}>
                        <img src={packagesWeldImg} alt={title} />
                    </div>
                    <div className={classes.image}>
                        <img src={packagesFormsImg} alt={productionTitle} />
                    </div>
                </div>
            </main>
        </>
    );
};

export default PackagesPage;
