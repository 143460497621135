import { useQuery } from '@apollo/client';

import { useLoading } from '../../hooks/useLoading';
import defaultOperations from '../../queries/getHomePage.gql';

export const useHomePage = () => {
    const { getHomePage } = defaultOperations;

    const { data, loading } = useQuery(getHomePage, {
        fetchPolicy: 'cache-and-network',
    });

    const {
        BannerTitle,
        BannerText,
        AboutUsPreTitle,
        AboutUsTitle,
        AboutUsDescription,
        EdgesOfferPreTitle,
        EdgesOfferTitle,
        EdgesOfferDescription,
        PackagesOfferPreTitle,
        PackagesOfferTitle,
        PackagesOfferDescription,
        TechnologyPreTitle,
        TechnologyTitle,
        TechnologyDescription,
    } = data?.homepage || {};

    const bannerData = {
        title: BannerTitle,
        text: BannerText,
    };

    const aboutUsData = {
        pretitle: AboutUsPreTitle,
        title: AboutUsTitle,
        description: AboutUsDescription,
    };

    const offerData = {
        edgesPretitle: EdgesOfferPreTitle,
        edgesTitle: EdgesOfferTitle,
        edgesDescription: EdgesOfferDescription,
        packagesPretitle: PackagesOfferPreTitle,
        packagesTitle: PackagesOfferTitle,
        packagesDescription: PackagesOfferDescription,
    };

    const technologyData = {
        pretitle: TechnologyPreTitle,
        title: TechnologyTitle,
        description: TechnologyDescription,
    };

    const isLoading = useLoading(loading, data);

    return { bannerData, aboutUsData, offerData, technologyData, isLoading };
};
