import { gql } from '@apollo/client';

const GET_EDGES_PAGE = gql`
    {
        edgesPage {
            Pretitle
            Title
            Description
        }
    }
`;

export default {
    getEdgesPage: GET_EDGES_PAGE,
};
