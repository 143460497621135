import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';

import { paths, logoType } from '../../constants';
import { useMinWidth } from '../../hooks/useMinWidth';
import Logo from '../Logo';
import MenuHamburger from '../MenuHamburger/menuHamburger';
import MobileMenu from '../MobileMenu/mobileMenu';
import classes from './header.module.css';

const leftSideMenuItems = [
    {
        id: 1,
        label: 'Strona główna',
        url: paths.homepage,
        children: null,
    },
    {
        id: 2,
        label: 'Oferta',
        url: null,
        children: [
            {
                id: 1,
                label: 'Obrzeża meblowe (ABS & PP)',
                url: paths.furnitureEdges,
            },
            {
                id: 2,
                label: 'Opakowania foliowe (LDPE & HDPE)',
                url: paths.foilWrapper,
            },
        ],
    },
];

const rightSideMenuItems = [
    {
        id: 3,
        label: 'O firmie',
        url: paths.aboutUs,
        children: null,
    },
    {
        id: 4,
        label: 'Kontakt',
        url: paths.contact,
        children: null,
    },
];

const Header = () => {
    const isDesktop = useMinWidth(1001);

    return isDesktop ? (
        <header className={classes.root}>
            <nav className={classes.navigation}>
                <ul className={classes.menu}>
                    {leftSideMenuItems.map((item) => {
                        return (
                            <li
                                key={item.id}
                                className={
                                    item.children
                                        ? [
                                              classes.menuItem,
                                              classes.withChildren,
                                          ].join(' ')
                                        : classes.menuItem
                                }
                            >
                                <Link to={item.url ? item.url : '#'}>
                                    {item.label}
                                </Link>
                                {item.children ? (
                                    <>
                                        <IoIosArrowDown
                                            className={classes.arrowDown}
                                        />
                                        <ul className={classes.submenu}>
                                            {item.children.map((child) => (
                                                <li key={child.id}>
                                                    <div
                                                        className={
                                                            classes.square
                                                        }
                                                    />
                                                    <Link to={child.url}>
                                                        {child.label}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : null}
                            </li>
                        );
                    })}
                </ul>
                <Logo tpye={logoType.dark} isClickable classes={classes.logo} />
                <ul className={classes.menu}>
                    {rightSideMenuItems.map((item) => (
                        <li key={item.id} className={classes.menuItem}>
                            <Link to={item.url}>{item.label}</Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </header>
    ) : (
        <header className={classes.root}>
            <Logo type={logoType.dark} isClickable classes={classes.logo} />
            <MenuHamburger />
            <MobileMenu items={[...leftSideMenuItems, ...rightSideMenuItems]} />
        </header>
    );
};

export default Header;
