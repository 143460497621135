import { gql } from '@apollo/client';

const GET_PACKAGES_PAGE = gql`
    {
        packagesPage {
            Pretitle
            Title
            Description
            ProductionPretitle
            ProductionTitle
            ProductionDescription
        }
    }
`;

export default {
    getPackagesPage: GET_PACKAGES_PAGE,
};
