import React, { createContext, useReducer, useContext } from 'react';

import { appActionsTypes } from '../constants';

const AppContext = createContext();

const initialState = {
    maintenanceMode: false,
    mobileMenuOpen: false,
    isLoading: false,
};

const appReducer = (state, action) => {
    switch (action.type) {
        case appActionsTypes.setMaintenance: {
            return { ...state, maintenanceMode: action.payload };
        }
        case appActionsTypes.setMobileMenuOpen: {
            return { ...state, mobileMenuOpen: action.payload };
        }
        case appActionsTypes.setIsLoading: {
            return { ...state, isLoading: action.payload };
        }
        default:
            return { ...state };
    }
};

export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, initialState);

    const value = { state, dispatch };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
    const { state, dispatch } = useContext(AppContext);

    return [{ appState: state }, { actions: dispatch }];
};
